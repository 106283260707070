import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import {IMediaElement, VgApiService, VgCoreModule} from "@videogular/ngx-videogular/core";
import {DatePipe} from "@angular/common";
import {DefaultDatePipe} from "../../_pipes/default-date.pipe";
import {NgbActiveModal, NgbModal, NgbNav, NgbNavContent, NgbNavLink} from "@ng-bootstrap/ng-bootstrap";
import {ReactiveFormsModule} from "@angular/forms";
import {TranslocoDirective} from "@ngneat/transloco";
import {MovieService} from "../../_services/movie.service";
import {VgControlsModule} from "@videogular/ngx-videogular/controls";
import {VgBufferingModule} from "@videogular/ngx-videogular/buffering";
import {VgOverlayPlayModule} from "@videogular/ngx-videogular/overlay-play";
import {Movie} from "../../_models/movie/movie";
import {catchError, merge, of} from "rxjs";
import {debounceTime, filter, tap} from "rxjs/operators";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {PlayerService} from "../../_services/player.service";

@Component({
  selector: 'app-movie-player-modal',
  standalone: true,
  imports: [
    VgCoreModule,
    ReactiveFormsModule,
    TranslocoDirective,
    VgControlsModule,
    VgBufferingModule,
    VgOverlayPlayModule
  ],
  templateUrl: './movie-player-modal.component.html',
  styleUrl: './movie-player-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MoviePlayerModalComponent implements OnInit {
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly destroyRef = inject(DestroyRef);
  private readonly movieService = inject(MovieService);
  private readonly playerService = inject(PlayerService);


  @Input({required: true}) movieCode!: string;

  mimeType: string = '';
  videoSrc: string = '';
  private videoApi: VgApiService | null = null;
  bootstrapped: boolean = false;


  ngOnInit(): void {
    this.loadMovie();
  }

  loadMovie() {
    this.movieService.getMimeType(this.movieCode).pipe(catchError(err => {
      return of(null)
    })).subscribe({
      next: (mime) => {
        if (!mime) {
          this.playerService.stopMovie();
          return;
        }
        this.mimeType = mime;
        this.videoSrc = this.movieService.getMovieStreamUrl(this.movieCode);
        this.cdRef.markForCheck();
      },
      error: (err) => console.error('Error fetching MIME type', err)
    });
  }


  onPlayerReady(api: VgApiService) {
    // Mute the player
    this.videoApi = api;

    // Build an observable stream to handle progress updates
    // merge(
    //   this.videoApi.getDefaultMedia().subscriptions.seeked,
    //   this.videoApi.getDefaultMedia().subscriptions.playing,
    // ).pipe(debounceTime(1000),
    //   takeUntilDestroyed(this.destroyRef),
    //   tap(p => {
    //
    //   }),
    // ).subscribe();




    this.setMovieDefaults();
  }

  setMovieDefaults() {
    if (this.videoApi == null) return;

    this.videoApi.volume = 0;
    this.videoApi.getDefaultMedia().subscriptions.canPlay.subscribe(m => {
      if (!this.bootstrapped) {
        this.videoApi?.fsAPI.toggleFullscreen();
        this.bootstrapped = true;
        this.videoApi?.play();
      }
    });

    // When closing fullscreen, close the modal
    this.videoApi?.fsAPI.onChangeFullscreen.pipe(
      filter((newState: boolean) => {
        return !newState;
      }),
      tap(_ => {
        console.log('stopping movie as not in fullscreen anymore');
        this.playerService.stopMovie();
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();

  }


}
