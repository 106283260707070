import { Injectable } from '@angular/core';
import {BehaviorSubject, ReplaySubject, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  private readonly playerVisibleSource = new BehaviorSubject<boolean>(false);
  public readonly playerVisible$ = this.playerVisibleSource.asObservable();

  private readonly movieCodeSource = new BehaviorSubject<string | null>(null);
  public readonly movieCode$ = this.movieCodeSource.asObservable();


  constructor() { }

  playMovie(movieCode: string) {
    this.movieCodeSource.next(movieCode);
    this.playerVisibleSource.next(true);
  }

  stopMovie() {
    this.movieCodeSource.next(null);
    this.playerVisibleSource.next(false);
  }
}
