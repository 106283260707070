import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Movie} from "../_models/movie/movie";
import {PaginatedResult, Pagination} from "../_models/pagination";
import {UtilityService} from "../shared/_services/utility.service";
import {map} from "rxjs/operators";
import {MovieReview} from "../_models/movie/movie-review";
import {TextResonse} from "../_types/text-response";
import {MovieFilter} from "../_models/filtering/movie-filter";

@Injectable({
  providedIn: 'root'
})
export class MovieService {

  baseUrl = environment.apiUrl;
  paginatedResults: PaginatedResult<Movie[]> = new PaginatedResult<Movie[]>();

  constructor(private httpClient: HttpClient, private utilityService: UtilityService) { }

  getAllMovies(filter: MovieFilter, pageNum: number | null = null, itemsPerPage: number | null = null) {
    let params = new HttpParams();
    params = this.utilityService.addPaginationIfExists(params, pageNum, itemsPerPage);

    return this.httpClient.post<PaginatedResult<Movie>>(this.baseUrl + 'movie/all', filter, {observe: 'response', params}).pipe(
      map((response: any) => {
        return this.utilityService.createPaginatedResult(response, this.paginatedResults);
      })
    );
  }

  getMoviesForLibrary(libraryId: number, pageNum?: number, itemsPerPage?: number) {
    let params = new HttpParams();
    params = this.utilityService.addPaginationIfExists(params, pageNum, itemsPerPage);

    return this.httpClient.post<PaginatedResult<Movie>>(this.baseUrl + 'movie?libraryId=' + libraryId, {}, {observe: 'response', params}).pipe(
      map((response: any) => {
        return this.utilityService.createPaginatedResult(response, this.paginatedResults);
      })
    );
  }

  getRecentlyAdded(pageNum?: number, itemsPerPage?: number) {
    let params = new HttpParams();
    params = this.utilityService.addPaginationIfExists(params, pageNum, itemsPerPage);

    return this.httpClient.get<PaginatedResult<Movie>>(this.baseUrl + 'movie/recently-added', {observe: 'response', params}).pipe(
      map((response: any) => {
        return this.utilityService.createPaginatedResult(response, this.paginatedResults);
      })
    );
  }

  getRecentlyReviewed(pageNum?: number, itemsPerPage?: number) {
    let params = new HttpParams();
    params = this.utilityService.addPaginationIfExists(params, pageNum, itemsPerPage);

    return this.httpClient.get<PaginatedResult<Movie>>(this.baseUrl + 'movie/recently-reviewed', {observe: 'response', params}).pipe(
      map((response: any) => {
        return this.utilityService.createPaginatedResult(response, this.paginatedResults);
      })
    );
  }

  getPopularMovies(pageNum?: number, itemsPerPage?: number) {
    let params = new HttpParams();
    params = this.utilityService.addPaginationIfExists(params, pageNum, itemsPerPage);

    return this.httpClient.get<PaginatedResult<Movie>>(this.baseUrl + 'movie/popular', {observe: 'response', params}).pipe(
      map((response: any) => {
        return this.utilityService.createPaginatedResult(response, this.paginatedResults);
      })
    );
  }

  getMovie(movieCode: string) {
    return this.httpClient.get<Movie>(this.baseUrl + 'movie?movieCode=' + movieCode);
  }

  updateReview(movieCode: string, review: MovieReview) {
    return this.httpClient.post(this.baseUrl + 'review', {movieCode: movieCode, review: review}, TextResonse);
  }

  scanMovie(movieCode: string) {
    return this.httpClient.post(this.baseUrl + 'movie/scan?movieCode=' + movieCode, {}, TextResonse);
  }

  refreshMetadata(movieCode: string) {
    return this.httpClient.post(this.baseUrl + 'movie/refresh-metadata?movieCode=' + movieCode, {}, TextResonse);
  }

  refreshCover(movieCode: string) {
    return this.httpClient.post(this.baseUrl + 'movie/refresh-cover?movieCode=' + movieCode, {}, TextResonse);
  }


  getMissingCovers(pageNum?: number, itemsPerPage?: number) {
    let params = new HttpParams();
    params = this.utilityService.addPaginationIfExists(params, pageNum, itemsPerPage);

    return this.httpClient.post<PaginatedResult<Movie>>(this.baseUrl + 'movie/missing-covers', {}, {observe: 'response', params}).pipe(
      map((response: any) => {
        return this.utilityService.createPaginatedResult(response, this.paginatedResults);
      })
    );
  }


  getMissingActors(pageNum?: number, itemsPerPage?: number) {
    let params = new HttpParams();
    params = this.utilityService.addPaginationIfExists(params, pageNum, itemsPerPage);

    return this.httpClient.post<PaginatedResult<Movie>>(this.baseUrl + 'movie/missing-actor', {}, {observe: 'response', params}).pipe(
      map((response: any) => {
        return this.utilityService.createPaginatedResult(response, this.paginatedResults);
      })
    );
  }

  getMimeType(movieCode: string) {
    return this.httpClient.get<string>(this.baseUrl + 'movie/mime-type?movieCode=' + movieCode, TextResonse);
  }

  getMovieStreamUrl(movieCode: string) {
    return this.baseUrl + 'movie/stream?movieCode=' + movieCode
  }
}
