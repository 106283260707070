import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { AccountService } from './account.service';
import {Movie} from "../_models/movie/movie";
import {Library} from "../_models/library/library";
import {Person} from "../_models/people/person";

export enum Action {
  Submenu = -1,
  /**
   * Invoke a Scan on Movie/Library
   */
  Scan = 1,
  /**
   * Refreshes a cover
   */
  RefreshCover = 2,
  /**
   * Refreshes metadata
   */
  RefreshMetadata = 3,
  /**
   * Edit Entity
   */
  Edit = 4

}

/**
 * Callback for an action
 */
export type ActionCallback<T> = (action: ActionItem<T>, data: T) => void;
export type ActionAllowedCallback<T> = (action: ActionItem<T>) => boolean;

export interface ActionItem<T> {
  title: string;
  action: Action;
  callback: ActionCallback<T>;
  requiresAdmin: boolean;
  children: Array<ActionItem<T>>;
  /**
   * An optional class which applies to an item. ie) danger on a delete action
   */
  class?: string;
  /**
   * Indicates that there exists a separate list will be loaded from an API.
   * Rule: If using this, only one child should exist in children with the Action for dynamicList.
   */
  dynamicList?: Observable<{title: string, data: any}[]> | undefined;
  /**
   * Extra data that needs to be sent back from the card item. Used mainly for dynamicList. This will be the item from dyanamicList return
   */
  _extra?: {title: string, data: any};
}

@Injectable({
  providedIn: 'root',
})
export class ActionFactoryService {
  libraryActions: Array<ActionItem<Library>> = [];
  movieActions: Array<ActionItem<Movie>> = [];
  personActions: Array<ActionItem<Person>> = [];

  isAdmin = false;

  constructor(private accountService: AccountService) {
    this.accountService.currentUser$.subscribe((user) => {
      if (user) {
        this.isAdmin = this.accountService.hasAdminRole(user);
      } else {
        this._resetActions();
        return; // If user is logged out, we don't need to do anything
      }

      this._resetActions();
    });
  }

  getLibraryActions(callback: ActionCallback<Library>) {
		return this.applyCallbackToList(this.libraryActions, callback);
  }

  getMovieActions(callback: ActionCallback<Movie>) {
		return this.applyCallbackToList(this.movieActions, callback);
  }

  getPersonActions(callback: ActionCallback<Person>) {
    return this.applyCallbackToList(this.personActions, callback);
  }


  dummyCallback(action: ActionItem<any>, data: any) {}


  private _resetActions() {
    this.libraryActions = [
      {
        action: Action.Scan,
        title: 'scan-library',
        callback: this.dummyCallback,
        requiresAdmin: true,
        children: [],
      }
    ];


    this.movieActions = [
      {
        action: Action.Scan,
        title: 'scan-series',
        callback: this.dummyCallback,
        requiresAdmin: true,
        children: [],
      },
      {
        action: Action.RefreshCover,
        title: 'refresh-cover',
        callback: this.dummyCallback,
        requiresAdmin: true,
        children: [],
      },
      {
        action: Action.RefreshMetadata,
        title: 'refresh-metadata',
        callback: this.dummyCallback,
        requiresAdmin: true,
        children: [],
      }
    ];

    this.personActions = [
      {
        action: Action.Edit,
        title: 'edit',
        callback: this.dummyCallback,
        requiresAdmin: true,
        children: [],
      },
    ];
  }

  private applyCallback(action: ActionItem<any>, callback: (action: ActionItem<any>, data: any) => void) {
    action.callback = callback;

    if (action.children === null || action.children?.length === 0) return;

    action.children?.forEach((childAction) => {
      this.applyCallback(childAction, callback);
    });
  }

	public applyCallbackToList(list: Array<ActionItem<any>>, callback: (action: ActionItem<any>, data: any) => void): Array<ActionItem<any>> {
		const actions = list.map((a) => {
			return { ...a };
		});
		actions.forEach((action) => this.applyCallback(action, callback));
		return actions;
	}

  // Checks the whole tree for the action and returns true if it exists
  public hasAction(actions: Array<ActionItem<any>>, action: Action) {
    if (actions.length === 0) return false;

    for (let i = 0; i < actions.length; i++)
    {
      if (actions[i].action === action) return true;
      if (this.hasAction(actions[i].children, action)) return true;
    }

    return false;
  }

}
